<template>
  <main class="bg-white">
    <div class="relative red-gray-800">
      <div class="absolute inset-0">
        <img class="hidden lg:block object-cover object-bottom w-full h-full"
             src="../assets/certificering_header.webp" width="1280" height="675" alt="machines_en_onderdelen"
        >

        <img class="block lg:hidden object-cover object-bottom w-full h-full"
             src="../assets/certificering_header_small.webp" width="320" height="196" alt="machines_en_onderdelen"
        >

        <div class="absolute inset-0 bg-gray-800" style="mix-blend-mode: hard-light;" aria-hidden="true"></div>
      </div>
      <div class="relative py-24 px-4 mx-auto max-w-7xl sm:py-32 sm:px-6 lg:px-8">
        <h1 class="text-3xl font-medium tracking-tight text-white sm:text-5xl lg:text-6xl">Certificeringen
        </h1>
        <p class="mt-6 max-w-3xl text-base text-gray-300 ">
          Bij JC Las en Montagetechniek zijn we gespecialiseerd in het monteren en onderhouden van hoogwaardige metaalproducten. Metalen constructies, machines of onderdelen</p>
      </div>
    </div>

    <div class="py-16 bg-gray-50 overflow-hidden">
      <div class="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
        <div class="text-base max-w-prose mx-auto lg:max-w-none">
          <h2 class="mt-2 text-2xl font-semibold tracking-tight leading-8 text-gray-900 uppercase sm:text-2xl">Opleidingen</h2>
        </div>
        <div class="relative z-10 text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
          <p class="text-md text-gray-600">Wij hechten veel waarde aan het op peil houden van onze kennis door het volgen van de juiste opleidingen.
            Door het continue opleiden van persooneel garanderen wij u dat het werk altijd door vakmensen wordt uitgevoerd.</p>
        </div>
        <div class="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
          <div class="relative z-10">
            <div class="prose text-gray-600 mx-auto lg:max-w-none mb-12 lg:mb-20">
              <h3 class="mt-2 text-2xl font-semibold tracking-tight leading-8 text-gray-900 uppercase sm:text-2xl">Kwaliteit</h3>
              <p>Kwaliteit staat bij ons voorop, door de juiste certificeringen te behalen kunnen wij dit garanderen aan onze klanten.</p>
            </div>

            <section class="hidden lg:block relative bg-white rounded-sm shadow-lg">
              <div class="py-8 px-6 rounded-t-lg sm:px-10 sm:pt-10 sm:pb-8">
                <img class="w-9/12" src="../assets/metaalunie.svg" alt="Metaalunie" width="300" height="80">
              </div>
            </section>
          </div>
          <div class="mt-12 relative text-base max-w-prose mx-auto lg:mt-0 lg:max-w-none">
            <svg class="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
              <defs>
                <pattern id="bedc54bc-7371-44a2-a2bc-dc68d819ae60" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"/>
                </pattern>
              </defs>
              <rect width="404" height="384" fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)"/>
            </svg>
            <div class="relative text-base mx-auto max-w-prose lg:max-w-none">
              <figure>
                <div class="aspect-w-6 aspect-h-8 lg:aspect-w-12 lg:aspect-h-7 lg:aspect-none">
                  <img class="rounded-xs object-cover object-center"
                       src="../assets/certificaten/cert-wall.webp" alt="certificaten" width="920" height="1205"
                  >
                </div>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name : "Certificering",

  metaInfo : {
    title : 'Certificering',
    meta  : [
      {
        name    : 'description',
        content : 'Wij hechten veel waarde aan het op peil houden van onze kennis door het volgen van de juiste opleidingen. Door het continue opleiden van persooneel garanderen wij u dat het werk altijd door vakmensen wordt uitgevoerd.'
      }
    ]
  }
}
</script>

<style scoped>

</style>
